<template>
  <div class="height_100">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待接单" name="first">
        <div class="flex">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="产品类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              ">
              关键词搜索
            </div>
            <!-- <div class="flex" @click="serach()">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div> -->
            <div class="flex" style="border: 1px solid #cccc;">
              <input type="text" v-model="search" placeholder="请输入内容" style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                " 
                 @keyup.enter="serach"/>
              <i @click="serach()" class="el-icon-search"
                style="font-size: 20px;margin-right: 20px; line-height: 40px"></i>
            </div>



          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="ID" label="ID" min-width="125" align="center"></el-table-column>
          <el-table-column prop="order_name" label="标题" min-width="135" align="center">
          </el-table-column>
          <el-table-column align="center" prop="type_name" label="类型" min-width="125"></el-table-column>
          <el-table-column prop="enterprise_name" label="公司名称" min-width="125" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" min-width="125" align="center">
            <template>
              <div>隐藏（接单之后展示）</div>
            </template>
          </el-table-column>
          <el-table-column prop="appeal" label="诉求内容" min-width="125" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="lookcontent(scope.row)">查看详情
              </el-button>
            </template>
          </el-table-column>

          <el-table-column prop="create_time" label="发布时间" min-width="165" align="center">
          </el-table-column>

          <el-table-column prop="content" label="操作" min-width="165" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="uporder(scope.row)">立即抢单</el-button>
            </template>
          </el-table-column>
        </el-table>


      </el-tab-pane>
      <el-tab-pane label="服务中" name="second">
        <div class="flex">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="产品类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              ">
              关键词搜索
            </div>
            <!-- <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div> -->
            <div class="flex" style="border: 1px solid #cccc;">
              <input type="text" v-model="search" placeholder="请输入内容" style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                " 
                 @keyup.enter="serach"/>
              <i @click="serach()" class="el-icon-search"
                style="font-size: 20px;margin-right: 20px; line-height: 40px"></i>
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="ID" label="ID" min-width="125" align="center"></el-table-column>
          <el-table-column prop="order_name" label="标题" min-width="135" align="center">
          </el-table-column>
          <el-table-column align="center" prop="type_name" label="类型" min-width="125"></el-table-column>
          <el-table-column prop="enterprise_name" label="公司名称" min-width="125" align="center">
          </el-table-column>
          <el-table-column prop="enterprise_mobile" label="联系方式" min-width="125" align="center">
          </el-table-column>
          <el-table-column prop="appeal" label="诉求内容" min-width="125" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="lookcontent(scope.row)">查看详情
              </el-button>
            </template>
          </el-table-column>

          <el-table-column prop="create_time" label="发布时间" min-width="165" align="center">
          </el-table-column>

          <el-table-column prop="content" label="操作" min-width="165" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="recommend(scope.row)">推荐产品</el-button>
              <el-button type="text" size="small" style="color: #fb1615" @click="cancel(scope.row)">取消订单</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- //已推荐产品 -->
        <el-dialog title="已推荐产品" :visible.sync="beenInformation" width="1464px">
          <div>
            <div class="flex marl_20">
              <div type="text" style="
                  width: 152px;
                  height: 40px;
                  background: rgb(255, 102, 24);
                  border-radius: 6px;
                  line-height: 40px;
                  color: rgb(255, 255, 255);
                  text-align: center;
                  cursor: pointer;
                " @click="beenInformationbtn()">
                点击推荐产品
              </div>
            </div>
            <el-table :data="beenLogData" style="width: 100%">
              <el-table-column prop="ID" label="ID" min-width="100" align="center"></el-table-column>
              <el-table-column prop="productname" label="产品名称" min-width="100" align="center">
              </el-table-column>
              <el-table-column align="center" prop="productype" label="产品类型" min-width="100"></el-table-column>
              <el-table-column prop="pattern" label="服务模式" min-width="100" align="center">
              </el-table-column>
              <el-table-column prop="mechanism_name" label="机构名称" min-width="100" align="center">
              </el-table-column>

              <el-table-column prop="spend" label="费用" min-width="100" align="center">
              </el-table-column>

              <el-table-column prop="name" label="联系人" min-width="100" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="联系方式" min-width="100" align="center">
              </el-table-column>

              <el-table-column prop="Email" label="邮箱" min-width="125" align="center">
              </el-table-column>

              <el-table-column prop="appeal" label="产品详情" min-width="125" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" style="color: #ff6618" @click="look(scope.row)">查看详情</el-button>
                </template>
              </el-table-column>
            </el-table>

          </div>
        </el-dialog>

        <!-- //推荐产品 -->
        <el-dialog title="推荐产品" :visible.sync="Information" width="1464px">
          <div>
            <div class="flex marl_20">
              <div style="
                  width: 100px;
                  line-height: 40px;
                  position: relative;
                  font-size: 14px;
                ">
                服务机构搜索
              </div>
              <div class="flex" style="border: 1px solid #e6e6e6">
                <input type="text" v-model="logsearch" placeholder="请输入内容" style="
                    width: 270px;
                    border: 1px solid transparent;
                    padding: 10px;
                  " 
                   @keyup.enter="logorderlist"
                  
                  />
                <!-- <el-input placeholder="请输入内容"></el-input> -->
                <i @click="logorderlist()" class="el-icon-search" style="
                    font-size: 20px;
                    line-height: 40px;
                    color: #808080;
                    padding-right: 10px;
                  "></i>
              </div>
            </div>
            <el-table :data="LogData" style="width: 100%">
              <el-table-column prop="ID" label="ID" min-width="100" align="center"></el-table-column>
              <el-table-column prop="productname" label="产品名称" min-width="100" align="center">
              </el-table-column>
              <el-table-column align="center" prop="productype" label="产品类型" min-width="100"></el-table-column>
              <el-table-column prop="pattern" label="服务模式" min-width="100" align="center">
              </el-table-column>
              <el-table-column prop="mechanism_name" label="机构名称" min-width="100" align="center">
              </el-table-column>

              <el-table-column prop="spend" label="费用" min-width="100" align="center">
              </el-table-column>

              <el-table-column prop="name" label="联系人" min-width="100" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="联系方式" min-width="100" align="center">
              </el-table-column>

              <el-table-column prop="Email" label="邮箱" min-width="125" align="center">
              </el-table-column>

              <el-table-column prop="appeal" label="产品详情" min-width="125" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" style="color: #ff6618" @click="look(scope.row)">查看详情</el-button>
                </template>
              </el-table-column>

              <el-table-column prop="content" label="操作" min-width="125" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" style="color: #ff6618" @click="logrecommend(scope.row)">推荐此产品给用户
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="third">
        <div class="flex">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="产品类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              ">
              关键词搜索
            </div>
            <!-- <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div> -->
            <div class="flex" style="border: 1px solid #cccc;">
              <input type="text" v-model="search" placeholder="请输入内容" style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                " 
                 @keyup.enter="serach"/>
              <i @click="serach()" class="el-icon-search"
                style="font-size: 20px;margin-right: 20px; line-height: 40px"></i>
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="ID" label="ID" min-width="125" align="center"></el-table-column>
          <el-table-column prop="order_name" label="标题" min-width="135" align="center">
          </el-table-column>
          <el-table-column align="center" prop="type_name" label="类型" min-width="125"></el-table-column>
          <el-table-column prop="enterprise_name" label="公司名称" min-width="125" align="center">
          </el-table-column>
          <el-table-column prop="enterprise_mobile" label="联系方式" min-width="125" align="center">
          </el-table-column>
          <el-table-column prop="order_name" label="诉求内容" min-width="125" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="lookcontent(scope.row)">查看详情
              </el-button>
            </template>
          </el-table-column>

          <el-table-column prop="create_time" label="发布时间" min-width="165" align="center">
          </el-table-column>

          <el-table-column prop="content" label="操作" min-width="165" align="center">
            <template>
              <el-button type="text" size="small" style="color: #ff6618">无</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="已取消" name="fourth">
        <div class="flex">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="产品类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              ">
              关键词搜索
            </div>
            <!--  <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div>
          </div> -->
            <div class="flex" style="border: 1px solid #cccc;">
              <input type="text" v-model="search" placeholder="请输入内容" style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                " 
                @keyup.enter="serach"/>
              <i @click="serach()" class="el-icon-search"
                style="font-size: 20px;margin-right: 20px; line-height: 40px"></i>
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="ID" label="ID" min-width="125" align="center"></el-table-column>
          <el-table-column prop="order_name" label="标题" min-width="135" align="center">
          </el-table-column>
          <el-table-column align="center" prop="type_name" label="类型" min-width="125"></el-table-column>
          <el-table-column prop="enterprise_name" label="公司名称" min-width="125" align="center">
          </el-table-column>
          <el-table-column prop="enterprise_mobile" label="联系方式" min-width="125" align="center">
          </el-table-column>
          <el-table-column prop="appeal" label="诉求内容" min-width="125" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="lookcontent(scope.row)">查看详情
              </el-button>
            </template>
          </el-table-column>

          <el-table-column prop="create_time" label="发布时间" min-width="165" align="center">
          </el-table-column>

          <el-table-column prop="content" label="操作" min-width="165" align="center">
            <template>
              <el-button type="text" size="small" style="color: #ff6618">无</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-dialog title="查看诉求详情" :visible.sync="dialogVisible" width="600px" center>
        <div>
          <div style="border-bottom: 3px solid #ffffff; padding: 10px 0px" class="size_18">
            诉求标题：{{ appealcontent.order_name }}
          </div>
          <div style="border-bottom: 3px solid #ffffff; padding: 10px 0px" class="size_18">
            诉求类型：{{ appealcontent.type_name }}
          </div>
          <div style="padding: 10px 0px" class="size_18">
            诉求内容：{{ appealcontent.content }}
          </div>
        </div>
      </el-dialog>
    </el-tabs>

    <el-pagination v-if="TableData.length != 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
      :layout="paginations.layout" :total="paginations.total">
    </el-pagination>
  </div>
</template>


<script>
export default {
  data() {
    return {
      activeName: "first",
      search: "",
      logsearch: "", //推荐产品搜索框
      Information: false,
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      //推荐产品分页
      logpaginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 5, // 1页显示多少条
        page_sizes: [ 10, 20, 50,90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      ruleForm: {
        region: "",
      },
      InformationFrom: [],
      rules: {},
      options: [],
      TableData: [],
      //推荐产品数据
      beenLogData: [],
      beenallLogData: [],
      //已推荐产品数据
      LogData: [],
      allLogData: [],
      dialogVisible: false, //查看诉求内容
      logid: "", //诉求订单id
      sta: 1,
      beenInformation: false,
      appealcontent: "",
    };
  },
  created() {
    // this.getcatelist();
    this.getorderlist();
    this.setlogPaginations();
  },
  methods: {
    look(item) {
      this.$router.push({ path: "/serveDetails", query: { id: item.ID } });
      console.log(item, 'item')
    },
    lookcontent(item) {
      this.dialogVisible = true;
      this.getpersonal(item);
    },
    //诉求弹窗内容
    getpersonal(item) {
      let data = {
        token: localStorage.eleToken,
        id: item.ID,
      };

      this.$get("/order_lst_d", data).then((res) => {
        console.log(res, "r879797895165es");
        this.appealcontent = res.result;
      });
    },
    // 已推荐产品
    beenInformationbtn() {
      this.Information = true;
      this.beenInformation = false;
      this.LogData = []
      this.logsearch = ''
    },
    //推荐产品
    logrecommend(item) {
      console.log(item);
      let data = {
        token: localStorage.eleToken,
        id: this.logid,
        service_product_id: item.ID,
      };
      // console.log(data, "data");
      this.$post("/order_save ", data).then((res) => {
        console.log(res, "res");
        if (res.status != 2) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.Information = false;
        }
      });
    },
    //点击推荐产品
    recommend(item) {
      console.log(item.ID, 'item.ID');
      this.logid = item.ID;
      this.beenInformation = true;
      this.logsearch = "";
      this.beenlogorderlist();
    },

    //推荐产品
    logorderlist() {
      console.log("789456");
      let data = {
        token: localStorage.eleToken,
        name: this.logsearch,
        page: this.logpaginations.page_index,
        limit: this.logpaginations.page_size,
      };

      this.$post("/service_product_select ", data).then((res) => {
        console.log(res, "res");
        this.allLogData = res.result;
        this.setlogPaginations();
      });
    },
    //已推荐产品
    beenlogorderlist() {
      console.log("789456");
      let data = {
        id: this.logid,
        token: localStorage.eleToken,
        page: this.logpaginations.page_index,
        limit: this.logpaginations.page_size,
      };
      
      this.$post("/order_product_lst ", data).then((res) => {
        console.log(res, "res");
        this.beenLogData = res.result;
        this.setlogPaginations();
      });
    },

    //取消订单
    cancel(item) {
      console.log(item);
      this.$confirm("是否取消订单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // type: "warning",
      })
        .then(() => {
          let data = {
            token: localStorage.eleToken,
            id: item.ID,
            status: 4,
          };

          this.$post("/order_commissioner_status ", data).then((res) => {
            console.log(res, "res");
            this.$message({
            type: "success",
            message: res.msg,
          });
            this.getorderlist();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //抢单
    uporder(item) {
      console.log(item,'item')
      console.log(item.ID);
      // service_product_del  post
      this.$confirm("是否抢单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // type: "warning",
      })
        .then(() => {
          this.postcurrency(item.ID);
          // console.log(this.TableData, "this.TableData");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //抢单
    specialist(item) {
      let data = {
        token: localStorage.eleToken,
        id: item,
      };

      this.$post("/order_commissioner_upd ", data).then((res) => {
        console.log(res, "res");
        this.getorderlist();
      });
    },

    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      console.log(data, "data");
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res456");
        if (res.status === 1) {
          this.specialist();
        } else if (res.status === 2) {
          setInterval(() => {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            // location.href = "http://sso.smeqh.cn:9000/realname/index";
            this.$message({
              type: "info",
              message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
            });
          }, 2000);
        }
      });
    },

    postcurrency(item) {
      let data = {
        token: localStorage.eleToken,
      };
      this.$post("commissioner_currency", data).then((res) => {
        console.log(res, "res");
        if (res.result.audit_status === 2) {
          this.specialist(item);
        } else if (
          res.result.audit_status === 0 ||
          res.result.audit_status === 3
        ) {
          this.authentication();
        } else if (res.result.audit_status === 1) {
          this.$message({
            type: "info",
            message: "审核中",
          });
        }
      });
    },

    //搜索
    serach() {
      this.getorderlist();
    },
    //获取下拉列表
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      });
    },
    //获取订单列表
    getorderlist(item) {
      let data = {
        token: localStorage.eleToken,
        keyword: this.search,
        page: item ? item : this.paginations.page_index,
        
        limit: this.paginations.page_size,
        status: this.sta,
        pro_cate_id: this.ruleForm.region === "" ? " " : this.ruleForm.region,
      };
      console.log(data, "data");
      this.$post("/order_commissioner_lst ", data).then((res) => {
        // console.log(res, "res");
        this.allTableData = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
        if (this.allTableData.length != 0) {
        this.TableData = this.allTableData.filter((item, index) => {
          return index < this.paginations.page_size;
        });
      } else {
        this.TableData = [];
      }


        console.log(this.allTableData, "this.allTableData");
      });
    },
    //切换状态
    handleClick(tab, event) {
      console.log(tab, event);
      this.sta = Number(tab.index) + 1;
      this.getorderlist();
      this.getcatelist();
    },

    // 分页
    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.getorderlist(page)
      this.paginations.page_index = page
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.TableData = tables;
      }
    },

    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.getorderlist()
      this.TableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },


    // 推荐产品分页
    loghandleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.logpaginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.logpaginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allLogData[i]) {
          tables.push(this.allLogData[i]);
        }
        this.LogData = tables;
      }
    },

    loghandleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.logpaginations.page_index = 1;
      this.logpaginations.page_size = page_size;
      this.LogData = this.allLogData.filter((item, index) => {
        return index < page_size;
      });
    },

    //重置分页
    setlogPaginations() {
      // 总页数
      this.logpaginations.total = this.allLogData.length;
      this.logpaginations.page_index = 1;
      this.logpaginations.page_size = 10;
      //设置默认的分页数据
      if (this.allLogData.length != 0) {
        this.LogData = this.allLogData.filter((item, index) => {
          return index < this.logpaginations.page_size;
        });
        console.log();
      } else {
        this.LogData = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #f2f2f2;
}

::v-deep .el-dialog__title {
  font-size: 14px;
}

//修改菜单颜色
/deep/.el-tabs__item.is-active {
  color: #ff7129 !important;
}

/deep/.el-tabs__active-bar {
  background-color: #ff7129 !important;
}

/deep/.el-tabs__item:hover {
  color: #ff7129 !important;
}

/deep/.el-form-item {
  margin: auto;
}
</style>
